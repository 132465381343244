import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ContentAnchor from '@papertrailio/react-web-core/components/ContentAnchor'
import { spacing } from '@papertrailio/react-web-core/style/theme'
import BottomCTA from '@papertrailio/react-web-core/components/text/BottomCTA'
import Button from '@papertrailio/react-web-core/components/Button'
import ContentContainer from '@papertrailio/react-web-core/components/ContentContainer'
import manufacturerLogoUrl from '@papertrailio/react-web-core/utils/manufacturerLogoUrl'

import Layout from '../components/Layout'
import Link from '../components/Link'
import Helmet from '../components/Helmet'
import HeaderContent from '../components/HeaderContent'
import Testimonial from '../components/Testimonial'
import slug from '../utils/slug'


const ContentContainerStyled = styled(ContentContainer)`
  margin-bottom: ${spacing.base * 4}px;
`

const TextContainer = styled.div`
  max-width: 60%;

  @media only screen and (max-width: 750px) {
    max-width: 100%;
  }
`

const ManufacturersList = styled.ul`
  margin: 0;
  list-style: none;
  margin-bottom: ${spacing.base * 2}px;
`
const ManufacturersListItem = styled.li`
  margin: ${spacing.halve}px ${spacing.base * 1.5}px ${spacing.halve}px 0;
  display: inline-block;
  vertical-align: middle;
`

const ManufacturerImage = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-bottom: ${spacing.base}px;
`

const IndexPage = ({ data }) => (
  <Layout
    headerContent={() => (
      <HeaderContent
        listPagePath="/"
        manufacturers={data.allProduct.distinct}
      />
    )}
  >
    <Helmet
      title="Search and explore the Product Directory"
      description="The Papertrail Product Directory is a resource which you can use to lookup product information. All the information comes direct from our manufacturer partners."
    />

    <ContentContainerStyled>
      <ContentAnchor />

      <TextContainer>
        <h1>
          What is this?
        </h1>
        <p>
          The Papertrail Product Directory is a resource which anyone can use to look up specific manufacturer product data. All the product information is provided by Papertrail partners or has been collected from Papertrail subscribers and publicly available information.
        </p>
        <p>
          You can search products from the following manufacturers:
        </p>
      </TextContainer>

      <ManufacturersList>
        {data.allProduct.distinct.map(manufacturer => (
          <ManufacturersListItem key={manufacturer}>
            <Link to={`/${slug(manufacturer)}/#content`}>
              <ManufacturerImage
                src={manufacturerLogoUrl(manufacturer, 150)}
                alt={`${manufacturer} logo`}
                title={manufacturer}
              />
            </Link>
          </ManufacturersListItem>
        ))}
      </ManufacturersList>

      <p>Want to add your company`s products?</p>

      <Button
        theme="primaryBlue"
        wide
        to="https://www.papertrail.io/about#Contact"
        target="_blank"
        Link={Link}
      >
        Get in touch
      </Button>
    </ContentContainerStyled>

    <Testimonial />

    <BottomCTA>
      <Button
        theme="primaryRed"
        href="https://www.papertrail.io/book-demo?utm_source=product-directory&utm_campaign=book-demo"
        track={{
          category: 'demoLink',
          action: 'click',
          label: 'bottom',
        }}
        Link={Link}
      >
        Book a demo
      </Button>
    </BottomCTA>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    allProduct {
      distinct(field: manufacturer)
    }
  }
`
